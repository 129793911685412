import { ApiService } from '@/axios'

export const getClients = () => {
    return ApiService.getAll('get_clients')
}

export const getDashboard = (data) => {
    return ApiService.post('client_dashboard', data)
}

export const createClient = (data) => {
    return ApiService.post('create_client', data)
}

export const updateClient = (data) => {
    return ApiService.post('update_client', data)
}

export const deleteClient = (data) => {
    return ApiService.post('delete_user', data)
}