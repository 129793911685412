<template>
  <div class="container-fluid dashboard">
    <LoaderComponent v-if="loading" />
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-6">
              <div class="colorCard">
                <h2>Matrículas</h2>
                <h6>Last {{ period }} days</h6>
                <h3>{{ dashboard.timely_matriculas }}</h3>
              </div>
            </div>
            <div class="col-md-6">
              <div class="colorCard">
                <h2>Contratos</h2>
                <h6>Last {{ period }} days</h6>
                <h3>{{ dashboard.timely_contratos }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 buttons">
          <div class="row">
            <div class="col-md-12">
              <div
                class="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div
                  class="btn-group mr-4"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas(2)"
                  >
                    2 dias
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas(7)"
                  >
                    7 dias
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas(30)"
                  >
                    30 dias
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas(45)"
                  >
                    45 dias
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas(60)"
                  >
                    60 dias
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="callMatriculas('all')"
                  >
                    Todo o período
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <date-range-picker
                v-model="dateRange"
                @update="getbyPeriod()"
              ></date-range-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container chartPeriod">
      <div class="row">
        <div
          class="col-md-6"
          @click="graphicMount(undefined, undefined, 'daily')"
        >
          <button>Diário</button>
        </div>
        <div
          class="col-md-6"
          @click="graphicMount(undefined, undefined, 'monthly')"
        >
          <button>Mensal</button>
        </div>
      </div>
    </div>
    <div class="container chart">
      <div class="row">
        <div class="col-md-6">
          <Barchart
            v-if="!emptyStats"
            :chartData.sync="statsGraphic.line.data"
            :chartOptions.sync="statsGraphic.options"
            :styles.sync="statsGraphic.line.styles"
          />
        </div>
        <div class="col-md-6">
          <Barchart
            v-if="!emptyStatsContract"
            :chartData.sync="statsContract.line.data"
            :chartOptions.sync="statsContract.options"
            :styles.sync="statsContract.line.styles"
          />
        </div>
      </div>
    </div>
      <div class="container tabs">
    <ul class="d-flex justify-content-around">
      <li @click="changeTab('matriculas')" class="tab matriculas" v-if="!emptyMatriculas">Matrículas</li>
      <li @click="changeTab('contratos')" class="tab contratos" v-if="!emptycontratos">Contratos</li>
    </ul>
  </div>
    <div class="container dash matriculas shadow-lg rounded" v-if="!emptyMatriculas">
          <List
      
      :fields.sync="statsGraphic.list"
      :type="'processadas'"
      :numberpages="20"
      :updated.sync="updated"
      :title="`Total de matrículas processadas`"
    />

    <div class="container filter"  >
      <div class="row">
        <div class="col-md-4">
          <label>Status da Matrícula:</label>
          <input
            type="text"
            class="form-group"
            v-model="filt.status"
            @change="filterbyStatus()"
          />
        </div>
        <div class="col-md-4">
          <label>Usuário:</label>
          <input
            type="text"
            class="form-group"
            v-model="filt.user"
            @change="filterbyUser()"
          />
        </div>
        <div class="col-md-4">
          <label>Matricula ID:</label>
          <input
            type="text"
            class="form-group"
            v-model="filt.matriculaId"
            @change="filterbyID()"
          />
        </div>
      </div>
    </div>
    <List
    
      :fields.sync="matriculas"
      :type="'matriculasInside'"
      :numberpages="20"
      :updated.sync="updated"
      :title="`Lista de Matrículas - last ${period} days - ${matriculas.length} matrículas`"
    />

    </div>

  <div class="container dash contratos shadow-lg rounded"  v-if="!emptycontratos">
        <List
    
      :fields.sync="statsContract.list"
      :type="'contractsProccess'"
      :numberpages="20"
      :updated.sync="updated"
      :title="`Total de contratos processados`"
    />

    <div class="container filter">
      <div class="row">
        <div class="col-md-6">
          <label>Status do Contrato:</label>
          <input
            type="text"
            class="form-group"
            v-model="filtContratos.status"
            @change="filterbyStatusContract()"
          />
        </div>

        <div class="col-md-6">
          <label>Contrato ID:</label>
          <input
            type="text"
            class="form-group"
            v-model="filtContratos.contratoID"
            @change="filterbyIDContract()"
          />
        </div>
      </div>
    </div>
    <List
     
      :fields.sync="contratos"
      :type="'contractsInside'"
      :numberpages="20"
      :updated.sync="updated"
      :title="`Lista de Contratos - last ${period} days - ${contratos.length} contratos`"
    />
  </div>


  </div>
</template>
<script type="text/javascript">
import { HomeMixin } from "./Home.mixin.js";
import List from "../../components/ui/list/list";
import Barchart from "../../components/ui/Barchart/Barchart";
import DateRangePicker from "vue2-daterange-picker";
import LoaderComponent from "../../components/ui/loader/loader";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  props: {},
  components: { List, Barchart, DateRangePicker, LoaderComponent },
  name: "Home",
  mixins: [HomeMixin],
};
</script>
<style src="./Home.less" lang="less" />
