import { ApiService } from '@/axios'

export const getUsers = () => {
    return ApiService.getAll('get_users')
}

export const createUser = (data) => {
    return ApiService.post('create_user', data)
}

export const updateUser = (data) => {
    return ApiService.post('update_user', data)
}

export const deleteUser = (data) => {
    return ApiService.post('delete_user', data)
}