export function Base(buttonName) {
    this.clients = []
    this.method = "post",
        this.button = {
            label: `${buttonName}`,
            width: "100%",
            padding: "10px",
            color: "#fff",
            background: "#40ce94",
            margin: "20px 0px 0px 0px",
            columns: "12",
        }

}

export function Newuser(clients) {
    Base.call(this, "Criar usuário");
    this.clientList = []
    this.endpoint = process.env.VUE_APP_API_ROOT + "create/user"
    this.fields = [{
            name: "username",
            type: "text",
            label: "Nome",
            columns: "6",
            model: null,
            multiValues: [],
            required: true,
            placeholder: "Digite o nome",
            message: "O campo nome é requerido",
            position: 1,
            config: {},
        },
        {
            name: "password",
            type: "password",
            label: "Senha",
            columns: "6",
            required: true,
            model: null,
            multiValues: [],
            placeholder: "Digite a senha",
            message: "O campo senha é requerido",
            position: 2,
            config: {},
        },
        {
            name: "user_type",
            type: "select",
            label: "Tipo de usuário",
            columns: "12",
            required: true,
            model: null,
            multiValues: [{
                    label: "API",
                    value: "api_only",
                },
                {
                    label: "Administrador",
                    value: "admin",
                },
                {
                    label: "Anotador",
                    value: "anotador",
                },
                {
                    label: "Cliente",
                    value: "client",
                },
                {
                    label: "Usuário Teste",
                    value: "testuser",
                },
            ],
            placeholder: "Selecione um tipo de usuário",
            message: "Usuário é requerido",
            position: 3,
            config: {},
        },
        {
            name: "client_name",
            type: "select",
            label: "Empresa",
            columns: "12",
            required: true,
            model: null,
            multiValues: clients,
            placeholder: "Selecione a empresa",
            message: "Empresa é requerido",
            position: 4,
            config: {},
        },
    ]
}
export function Newcustomer() {
    Base.call(this, "Criar cliente");
    this.endpoint = process.env.VUE_APP_API_ROOT + "v1/create_client",
        this.fields = [{
                name: "name",
                type: "text",
                label: "Nome do cliente",
                columns: "6",
                model: null,
                multiValues: [],
                required: true,
                placeholder: "Digite o nome do cliente",
                message: "O campo nome do cliente é requerido",
                position: 1,
                config: {},
            },
            {
                name: "contractlimit",
                type: "number",
                label: "Limite de Contratos",
                columns: "6",
                required: true,
                model: null,
                multiValues: [],
                placeholder: "Digite o limite de contratos",
                message: "O campo limite de contratos é obrigatório",
                position: 2,
                config: {},
            },

            {
                name: "matriculalimit",
                type: "number",
                label: "Limite de Matrículas",
                columns: "6",
                required: true,
                model: null,
                multiValues: [],
                placeholder: "Digite o limite de matrículas",
                message: "O campo limite de matrículas é obrigatório",
                position: 3,
                config: {},
            },

            {
                name: "expiration",
                type: "date_normal",
                label: "Validade",
                columns: "6",
                required: true,
                model: null,
                multiValues: [],
                placeholder: "Digite a validade",
                message: "O validade é obrigatória",
                position: 4,
                config: {
                    type: "",
                },
            },
            {
                name: "active",
                type: "radio",
                label: " Data",
                columns: "12",
                required: true,
                model: null,
                multiValues: [{
                        columns: "6",
                        label: "Inativo",
                        value: "inactive",
                        checked: false,
                    },
                    {
                        columns: "6",
                        label: "Ativo",
                        value: "active",
                        checked: false,
                    },
                ],
                placeholder: "",
                message: "A validade é obrigatória",
                position: 5,

            },
        ];
}

export function Editcustomer() {
    Base.call(this, "Editar Cliente");
    this.endpoint = process.env.VUE_APP_API_ROOT + "v1/create_client"
    this.fields = [];
}

export function Edituser() {
    Base.call(this, "Editar Usuário");
    this.endpoint = process.env.VUE_APP_API_ROOT + "v1/create_client"
    this.fields = [];
}

Base.prototype.count = function() {
    return this.fields.length;
};

Newcustomer.prototype = Object.create(Base.prototype);
Editcustomer.prototype = Object.create(Base.prototype);