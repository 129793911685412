import { getDashboard } from '../../services/dashboard.services'
import { mapGetters } from 'vuex'
import { duplicateObject, objEmpty } from '../../helpers/helpers'
import { Clientscharts, Contractschart } from "../../factories/staticGraphicFactory"
import moment from 'moment'
import * as jquery from 'jquery'
const $ = jquery

export const HomeMixin = {
    data() {
        return {
            loading: true,
            dashboard: {},
            dashboardClean: {},
            updated: '',
            statsGraphic: {},
            statsContract: {},
            period: 1,
            chartPeriod: 'daily',
            filt: {
                status: '',
                user: '',
                data: '',
                matriculaId: ''
            },
            filtContratos: {
                status: '',
                user: '',
                data: '',
                contratoID: ''
            },
            contratos: [],
            matriculas: [],
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            title: '',
        };
    },
    created() {

    },
    mounted() {

        this.callMatriculas(1)

    },
    watch: {

    },
    computed: {
        ...mapGetters(['getClientid']),
        emptyStats: function() {
            return objEmpty(this.statsGraphic)
        },
        emptyMatriculas: function() {
            if (this.matriculas.length === 0) return true
            else return false
        },
        emptyStatsContract: function() {
            return objEmpty(this.statsContract)
        },
        emptycontratos: function() {
            if (this.contratos.length === 0) return true
            else return false
        }
    },
    methods: {
        async callMatriculas(period) {

            this.period = period
            try {
                this.loading = true
                let initial = {
                    type: 'byPeriod',
                    period: this.period,
                }
                const { data } = await getDashboard(initial)
                this.dashboard = duplicateObject(data.data)
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.graphicMount(duplicateObject(this.dashboard.matriculas), duplicateObject(this.dashboard.contratos))
                this.loading = false
                this.$nextTick(() => {
                    this.refreshTabs()
                })
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },
        async getbyPeriod() {
            this.period = 'custom'
            try {
                this.loading = true
                let initial = {
                    period: 'byDate',
                    startDate: moment(this.dateRange.startDate).format("L"),
                    endDate: moment(this.dateRange.endDate).format("L"),
                }
                const { data } = await getDashboard(initial)
                this.dashboard = duplicateObject(data.data)
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.graphicMount(duplicateObject(this.dashboard.matriculas), duplicateObject(this.dashboard.contratos))
                this.loading = false
                this.$nextTick(() => {
                    this.refreshTabs()
                })
            } catch (error) {
                this.loading = false
                throw console.log(error)
            }
        },

        getFromcalendar() {
            this.getbyPeriod()

        },
        refreshTabs() {
            if (this.matriculas.length > 0) {
                $(`li.tab`).removeClass('active')
                $(`li.tab.matriculas`).addClass('active')
                $(`.dash.matriculas`).slideDown(500)
                $(`.dash.contratos`).slideUp(500)
            } else {
                $(`li.tab`).removeClass('active')
                $(`li.tab.contratos`).addClass('active')
                $(`.dash.contratos`).slideDown(500)
                $(`.dash.matriculas`).slideUp(500)
            }

        },
        changeTab(type) {
            $(`li.tab`).removeClass('active')
            $(`li.tab.${type}`).addClass('active')
            $(`.dash`).slideUp(500)
            $(`.dash.${type}`).slideDown(500)



        },
        graphicMount(matricula = duplicateObject(this.dashboard.matriculas), contrato = duplicateObject(this.dashboard.contratos), period = 'daily') {
            this.statsGraphic = new Clientscharts(period)
            this.statsGraphic.labels = this.statsGraphic.mountLabels(matricula)
            this.statsGraphic.stats = this.statsGraphic.mountStats(matricula)
            this.statsGraphic.mountChart(this.statsGraphic.labels, this.statsGraphic.stats, matricula)
            this.statsGraphic.mountList(this.statsGraphic.labels, this.statsGraphic.stats, matricula)

            this.statsContract = new Contractschart(period)
            this.statsContract.labels = this.statsContract.mountLabels(contrato)
            this.statsContract.stats = this.statsContract.mountStats(contrato)
            this.statsContract.mountChart(this.statsContract.labels, this.statsContract.stats, contrato)
            this.statsContract.mountList(this.statsContract.labels, this.statsContract.stats, contrato)

        },
        cleanSearch() {
            this.filt.status = '',
                this.filt.user = '',
                this.filt.matriculaId = '',
                this.filt.data = ''
        },
        cleanSearchContract() {
            this.filtContratos.status = '',
                this.filt.user = '',
                this.filt.contratoID = '',
                this.filt.data = ''
        },
        filterbyUser() {
            this.matriculas = this.matriculas.filter((val) => {
                if (this.filt.user.match(val.user)) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyUser() {
            this.matriculas = this.matriculas.filter((val) => {
                if (this.filt.user.match(val.user)) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyStatus() {
            this.matriculas = this.matriculas.filter((val) => {
                if (parseInt(this.filt.status) === val.status) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyID() {
            this.matriculas = this.matriculas.filter((val) => {
                if (parseInt(this.filt.matriculaId) === val.id) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyDate() {
            this.matriculas = this.matriculas.filter((val) => {
                if (this.filt.data === val.data) {
                    return val
                }
            })
            if (this.matriculas.length === 0) {
                this.matriculas = duplicateObject(this.dashboard.matriculas)
                this.cleanSearch()
            }
            this.$forceUpdate();
        },
        filterbyStatusContract() {
            this.contratos = this.contratos.filter((val) => {
                if (parseInt(this.filt.status) === val.progress) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearchcontract()
            }
            this.$forceUpdate();
        },
        filterbyIDContract() {
            this.contratos = this.contratos.filter((val) => {
                if (this.filt.contratoID === val.id) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearchcontract()
            }
            this.$forceUpdate();
        },
        filterbyDateContract() {
            this.contratos = this.contratos.filter((val) => {
                if (this.filt.data === val.data) {
                    return val
                }
            })
            if (this.contratos.length === 0) {
                this.contratos = duplicateObject(this.dashboard.contratos)
                this.cleanSearchcontract()
            }
            this.$forceUpdate();
        },

    }

};