import {
    Customers,
    Matriculas,
    Contracts,
    ContractsProccess,
    MatriculasProccess,
    Users,
    UsersInside,
} from "../../../factories/staticListsfactory";
import { getClients, deleteClient } from "../../../services/client.services";
import { deleteUser, getUsers } from "../../../services/user.service";
import { duplicateObject } from "../../../helpers/helpers";
import moment from "moment";
import { mapGetters } from "vuex";
export const Listmixins = {
    props: ["type", "numberpages", "title", "updated", "fields"],
    data() {
        return {
            clients: [],
            users: [],
            matriculas: [],
            matriculasProcessadas: [],
            listFields: [],
            _listFields: [],
            paginated: {},
            pages: [],
            typeField: {},
            searchPage: "",
            modal: false,
            editList: {},
            paginationType: "fast",
            pageLongIndex: 1,
            pageIndex: 1,
            totalPages: 0,
        };
    },
    computed: {
        ...mapGetters(["getClientname", "getClientid"]),
    },
    created() {},
    mounted() {
        if (this.type === "customers") {
            this.cliente();
        }
        if (this.type === "users") {
            this.usuario();
        }
        if (this.type === "userInside") {
            this.usuarioInside();
        }
        if (this.type === "matriculasInside") {
            this.matriculasInside();
        }
        if (this.type === "processadas") {
            this.matriculasProccessed();
        }
        if (this.type === "contractsInside") {
            this.contractsInside();
        }
        if (this.type === "contractsProccess") {
            this.contractsProccess();
        }
    },
    watch: {
        fields: function() {
            this.pageLongIndex = 1;
            this.matriculasInside();
        },
        updated: function(value) {
            // watch it
            if (value === "customers") {
                this.cliente();
            }
            if (value === "users") {
                this.usuario();
            }
            if (value === "userInside") {
                this.usuarioInside();
            }
            if (value === "matriculasInside") {
                this.matriculasInside();
            }
            if (this.type === "processadas") {
                this.matriculasProccessed();
            }
            if (this.type === "contractsInside") {
                this.contractsInside();
            }

            if (this.type === "contractsProccess") {
                this.contractsProccess();
            }
        },
    },
    methods: {
        paginate() {
            this.paginated = this.typeField.paginateFields(
                duplicateObject(this.typeField.fieldsList),
                this.pageLongIndex,
                this.numberpages
            );
            this.$nextTick(() => {
                this.mountPages(this.paginated.total_pages);
            });
        },
        changePage(index) {
            this.pageLongIndex = index;
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );
            this.pages = this.pages.map((val) => {
                if (val.page === index) val.status = "active";
                else val.status = "inactive";
                return val;
            });
            this.$forceUpdate();
        },
        mountPages(totalPages) {
            this.pages = [];
            this.totalPages = totalPages;
            if (totalPages < 15) {
                this.paginationType = "fast";
                for (let i = 1; i <= totalPages; i++) {
                    this.pages.push({
                        page: i,
                        status: "inactive",
                    });
                    this.pages[0].status = "active";
                }
            } else {
                this.paginationType = "long";
                this.pages.push({
                    page: "Next",
                    status: "next",
                });
                this.pages.unshift({
                    page: "Previous",
                    status: "previous",
                });
            }
        },
        closeModal() {
            this.modal = false;
            if (this.type === "customers") this.cliente();
            else this.usuario();
            this.$forceUpdate();
        },
        openModal(list) {
            this.modal = true;
            this.editList = list;
            this.$forceUpdate();
        },
        delClient(id, name) {
            let del = {
                ID: id,
                client_name: name,
            };
            let confirm = window.confirm(`Deseja mesmo excluir o cliente ${name}`);
            if (confirm) {
                deleteClient(del)
                    .then(() => {
                        this.cliente();
                        this.$toast.success("Cliente excluído com sucesso");
                    })
                    .catch((e) => {
                        this.$tokentest(e.response.status)
                        this.$toast.error("Houve um erro ao excluir o cliente");
                    });
            }
        },
        viewClient(list) {
            const payload = {
                client_id: list._id.value,
                client_name: list.client_name.value,
            };
            this.$store.dispatch("setClient", payload);
            this.$router.push("/dashboard-client");
        },
        delUser(name, client) {
            let del = {
                username: name,
                client_name: client,
            };
            let confirm = window.confirm(`Deseja mesmo excluir o usuario ${name}`);
            if (confirm) {
                deleteUser(del)
                    .then(() => {
                        this.usuario();
                        this.$toast.success("Usuário excluído com sucesso");
                    })
                    .catch((e) => {
                        this.$tokentest(e.response.status)
                        this.$toast.error("Houve um erro ao excluir o usuário");
                    });
            }
        },
        cliente() {
            getClients().then((res) => {
                this.clients = [];
                this.clients = duplicateObject(JSON.parse(res.data.data).clients);
                this.clients.filter((el) => {
                    el.created_date.value = new Date(el.created_date.value);
                    el.created_date.value = moment(el.created_date.value).format("L");
                    if (el.expiry_date.value !== "") {
                        el.expiry_date.value = moment(
                            new Date(el.expiry_date.value.$date)
                        ).format("L");
                    } else {
                        el.expiry_date.value = "";
                    }
                    el.users = el.usercount;
                    return el;
                });
                this.listFields = [];
                this.listFields = duplicateObject(this.clients);
                this._listFields = duplicateObject(this.clients);
                this.typeField = new Object();
                this.typeField = new Customers(this.listFields);
                this.paginate();
                this.$forceUpdate();
            });
        },
        usuario() {
            getUsers().then((res) => {
                this.listFields = [];
                this.listFields = duplicateObject(JSON.parse(res.data.data));
                this._listFields = duplicateObject(JSON.parse(res.data.data));
                this.typeField = new Object();
                this.typeField = new Users(this.listFields);
                this.paginate();
                this.$forceUpdate();
            });
        },
        usuarioInside() {
            this.listFields = [];
            this.listFields = duplicateObject(this.fields.users);
            this.typeField = new Object();
            this.typeField = new UsersInside(this.listFields);
            this.paginate();
            this.$forceUpdate();
        },
        matriculasInside() {
            this.listFields = [];
            this.listFields = duplicateObject(this.fields);
            this.typeField = new Object();
            this.typeField = new Matriculas(this.listFields);
            this.paginate();
            this.$forceUpdate();
        },
        contractsInside() {
            this.listFields = [];
            this.listFields = duplicateObject(this.fields);
            this.typeField = new Object();
            this.typeField = new Contracts(this.listFields);
            this.paginate();
            this.$forceUpdate();
        },
        contractsProccess() {
            this.listFields = [];
            this.listFields = duplicateObject(this.fields);
            this.typeField = new Object();
            this.typeField = new ContractsProccess(this.listFields);
            this.paginate();
            this.$forceUpdate();
        },
        matriculasProccessed() {
            this.listFields = [];
            this.listFields = duplicateObject(this.fields);
            this.typeField = new Object();
            this.typeField = new MatriculasProccess(this.listFields);
            this.paginate();
            this.$forceUpdate();
        },

        previousPage() {
            if (this.pageLongIndex === 1) {
                this.pageLongIndex = this.totalPages;
            } else {
                this.pageLongIndex = this.pageLongIndex - 1;
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );
            this.$forceUpdate();
        },
        nextPage() {
            if (this.pageLongIndex === this.totalPages) {
                this.pageLongIndex = 1;
            } else {
                this.pageLongIndex = this.pageLongIndex + 1;
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );
            this.$forceUpdate();
        },
        inputChange(index) {
            if (index > this.totalPages) {
                this.pageLongIndex = this.totalPages;
            } else if (index < 1) {
                this.pageLongIndex = 1;
            } else {
                this.pageLongIndex = index;
            }
            this.paginated = this.typeField.paginateFields(
                this.typeField.fieldsList,
                this.pageLongIndex,
                this.numberpages
            );
            this.$forceUpdate();
        },
        orderData() {
            this.typeField.fieldsList.sort((a, b) => {
                if (a.datareal > b.datareal) return 1;
                else if (a.datareal < b.datareal) return 1;
                else return 0;
            });
            this.paginate();
            this.$forceUpdate();
        },
        orderStatus() {
            this.typeField.fieldsList.sort((a, b) => {
                if (a.status > b.status) return 1;
                else if (a.status < b.status) return 1;
                else return 0;
            });
            this.paginate();
            this.$forceUpdate();
        },
        orderUser() {
            this.typeField.fieldsList.sort((a, b) => {
                if (a.user > b.user) return 1;
                else if (a.user < b.user) return 1;
                else 0;
            });
            this.paginate();
            this.$forceUpdate();
        },

        search(event, field) {
            setTimeout(() => {
                this.pageLongIndex = 1;
                this.listFields = this.listFields.filter(val => {
                    if (val[field].value.match(event.target.value.toLowerCase())) {
                        return val
                    }
                })
                if (this.listFields.length > 0) {
                    this.typeField = new Object();
                    this.typeField = new MatriculasProccess(this.listFields);
                    this.paginate();
                    this.$forceUpdate();
                    this.listFields = this.$duplicate(this._listFields)
                } else {
                    this.listFields = this.$duplicate(this._listFields)
                    this.typeField = new Object();
                    this.typeField = new MatriculasProccess(this.listFields);
                    this.paginate();
                    this.$forceUpdate();

                }

            }, 1500)
        },
        searchUser(event, field) {
            setTimeout(() => {
                this.pageLongIndex = 1;
                this.listFields = this.listFields.filter(val => {
                    if (val[field].value.match(event.target.value.toLowerCase())) {
                        return val
                    }
                })
                if (this.listFields.length > 0) {
                    this.typeField = new Object();
                    this.typeField = new Users(this.listFields);
                    this.paginate();
                    this.$forceUpdate();
                    this.listFields = this.$duplicate(this._listFields)
                } else {
                    this.listFields = this.$duplicate(this._listFields)
                    this.typeField = new Object();
                    this.typeField = new new Users(this.listFields);
                    this.paginate();
                    this.$forceUpdate();

                }

            }, 1500)
        },
        searchClient(event, field) {
            setTimeout(() => {
                this.pageLongIndex = 1;
                this.listFields = this.listFields.filter(val => {
                    if (val[field].value.match(event.target.value.toLowerCase())) {
                        return val
                    }
                })
                if (this.listFields.length > 0) {
                    this.typeField = new Object();
                    this.typeField = new Customers(this.listFields);
                    this.paginate();
                    this.$forceUpdate();
                    this.listFields = this.$duplicate(this._listFields)
                } else {
                    this.listFields = this.$duplicate(this._listFields)
                    this.typeField = new Object();
                    this.typeField = new Customers(this.listFields);
                    this.paginate();
                    this.$forceUpdate();

                }

            }, 1500)
        },
    },
};