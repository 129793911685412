<template>
  <div>
    <Modal
      v-if="modal === true"
      :fields="editList"
      :type="type"
      @closeModal="closeModal"
    />
    <div class="container fieldList">
      <h2>{{ title }}</h2>
      <div class="row" v-if="type === 'users'">
       <input class="form-group" type="text" placeholder="Buscar por nome" @keyup="searchUser($event,'username')"/>
      </div>
      <div class="row" v-if="type === 'customers'">
       <input class="form-group" placeholder="Buscar por nome" @keyup="searchClient($event,'client_name')"/>
      </div>
      <table class="table table-striped" v-if="type === 'customers'">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Last Activity</th>
            <th scope="col">Matrículas</th>
            <th scope="col">Contratos</th>
            <th scope="col">Users</th>
            <th scope="col">Expira em</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td class="clickLabel" @click="viewClient(list)">
              {{ list.client_name.value }}
            </td>
            <td>-</td>
            <td>{{ list.processed_matriculas.value }}</td>
            <td>{{ list.processed_contratos.value }}</td>
            <td>{{ list.usercount }}</td>
            <td>{{ list.expiry_date.value }}</td>
            <td>{{ list.active.value }}</td>

            <td class="image">
              <img src="@/assets/images/eye.png" @click="viewClient(list)" />
            </td>
            <td class="image">
              <img src="@/assets/images/edit.png" @click="openModal(list)" />
            </td>
            <td class="image">
              <img
                src="@/assets/images/rubbish-bin.png"
                @click="delClient(list._id.value, list.client_name.value)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-striped" v-if="type === 'users'">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Cliente</th>
            <th scope="col">Tipo</th>
            <th scope="col">Matrículas</th>
            <th scope="col">Contratos</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td class="clickLabel" @click="openModal(list)">
              {{ list.username.value }}
            </td>
            <td>{{ list.client_name.value }}</td>
            <td>{{ list.user_type.value }}</td>
            <td>{{ list.processed_matriculas.value }}</td>
            <td>{{ list.processed_contratos.value }}</td>
            <td>{{ list.active.value }}</td>
            <td class="image">
              <img src="@/assets/images/edit.png" @click="openModal(list)" />
            </td>
            <td class="image">
              <img
                src="@/assets/images/rubbish-bin.png"
                @click="delUser(list.username.value, list.client_name.value)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-striped" v-if="type === 'userInside'">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Matrículas</th>
            <th scope="col">Contratos</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>{{ list.username }}</td>
            <td>{{ list.processed_matriculas }}</td>
            <td>{{ list.processed_contratos }}</td>
            <td>{{ list.active }}</td>
            <td class="image"></td>
            <td class="image">
              <img
                src="@/assets/images/rubbish-bin.png"
                @click="delUser(list.username, fields.client_name)"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" v-if="type === 'processadas'">
        <thead>
          <tr>
            <th scope="col">Data</th>

            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>{{ list.data }}</td>

            <td>{{ list.total }}</td>
          </tr>
          <tr>
            <td colspan="4">
              <download-excel
                :class="`btn btn-download`"
                :data="typeField.fieldsList"
                :worksheet="`Matrículas Processadas`"
                :name="`matriculasprocessadas${new Date()}.xls`"
              >
                Exportar total de matrículas processadas
                <img src="@/assets/images/exceldownload.png" />
              </download-excel>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" v-if="type === 'contractsProccess'">
        <thead>
          <tr>
            <th scope="col">Data</th>

            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>{{ list.data }}</td>

            <td>{{ list.total }}</td>
          </tr>
          <tr>
            <td colspan="4">
              <download-excel
                :class="`btn btn-download`"
                :data="typeField.fieldsList"
                :worksheet="`Contratos Processados`"
                :name="`contratosprocessados${new Date()}.xls`"
              >
                Exportar total de contratos processados
                <img src="@/assets/images/exceldownload.png" />
              </download-excel>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" v-if="type === 'contractsInside'">
        <thead>
          <tr>
            <th scope="col">Data</th>
            <th scope="col">ID</th>
            <th scope="col">Progresso</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>{{ list.date }}</td>
            <td>{{ list.id }}</td>
            <td>{{ list.progress }}</td>
          </tr>
          <tr>
            <td colspan="4">
              <download-excel
                :class="`btn btn-download`"
                :data="typeField.fieldsList"
                :worksheet="`Contracts`"
                name="contracts.xls"
              >
                Exportar
                <img src="@/assets/images/exceldownload.png" />
              </download-excel>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-striped" v-if="type === 'matriculasInside'">
        <thead>
          <tr>
            <th scope="col" @click="orderData()">Data</th>
            <th scope="col">ID</th>
            <th scope="col" @click="orderStatus()">Status</th>
            <th scope="col" @click="orderUser()">User</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, key) in paginated.data" :key="key">
            <td>{{ list.date }}</td>
            <td>{{ list.id }}</td>
            <td>{{ list.status }}</td>
            <td>{{ list.user }}</td>
          </tr>
          <tr>
            <td colspan="4">
              <download-excel
                :class="`btn btn-download`"
                :data="typeField.fieldsList"
                :worksheet="`Matrículas`"
                name="matriculas.xls"
              >
                Exportar
                <img src="@/assets/images/exceldownload.png" />
              </download-excel>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row" v-if="paginationType === 'fast'">
        <div class="pagination">
          <a
            v-for="(page, key) in pages"
            :key="key"
            :class="`${page.status}`"
            @click="changePage(page.page)"
            >{{ page.page }}</a
          >
        </div>
      </div>
      <div class="row pages" v-if="paginationType === 'long'">
        <div class="col-md-2">
          <div class="pagination">
            <a :class="`${pages[0].status}`" @click="previousPage()">{{
              pages[0].page
            }}</a>
            <a :class="`${pages[1].status}`" @click="nextPage()">{{
              pages[1].page
            }}</a>
          </div>
        </div>
        <div class="col-md-2">
          <h3>{{ pageLongIndex }} de {{ totalPages }} páginas</h3>
        </div>
        <div class="col-md-4">
          <input
            class="form-group"
            type="text"
            v-model="searchPage"
            placeholder="Procurar página..."
            @change="inputChange(searchPage)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import Modal from "../form/modal/modal";

import { Listmixins } from "./list.mixins";
export default {
  name: "List",
  components: {
    Modal,

  },
  mixins: [Listmixins],
};
</script>
<style src="./list.less" lang="less" />
