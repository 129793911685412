import moment from "moment";
import { string_to_slug } from '../helpers/helpers'

export class Baselist {
    constructor() {
        this.fieldsList = []
    }
    createFields(fields) {
        this.fieldsList = fields

    }
    orderMatriculas() {
        this.fields = this.fields.sort((a, b) => {
            return -1
        })

    }
    orderContracts() {
        this.fields = this.fields.sort((a, b) => {
            return -1
        })

    }
    orderUsers() {
        this.fields = this.fields.sort((a, b) => {

            if (string_to_slug(a.client_name.value) < string_to_slug(b.client_name.value)) return -1
        })

    }
    orderUsersinside() {
        this.fields = this.fields.sort((a, b) => {
            if (string_to_slug(a.username) < string_to_slug(b.username)) return -1
        })

    }
    orderCustomers() {
        console.log(this.fields)
        this.fields = this.fields.sort((a, b) => {
            if (string_to_slug(a.client_name.value) < string_to_slug(b.client_name.value)) return -1
        })

    }
    paginateFields(items, current_page, per_page_items) {
        let page = current_page || 1,
            per_page = per_page_items || 10,
            offset = (page - 1) * per_page,
            paginatedItems = items.slice(offset).slice(0, per_page_items),
            total_pages = Math.ceil(items.length / per_page);

        return {
            page: page,
            per_page: per_page,
            pre_page: page - 1 ? page - 1 : null,
            next_page: (total_pages > page) ? page + 1 : null,
            total: items.length,
            total_pages: total_pages,
            data: paginatedItems
        };

    }
}

export class Customers extends Baselist {
    constructor(list) {
        super()
        this.fields = list
        
        this.orderCustomers()
        this.createFields(this.fields)
    }
}

export class Users extends Baselist {
    constructor(list) {
        super()
        this.fields = list
 
        this.orderUsers()
        this.createFields(this.fields)
    }
}

export class UsersInside extends Baselist {
    constructor(list) {
        super()
        this.fields = list
        this.orderUsersinside()
        this.createFields(this.fields)
    }
}

export class Matriculas extends Baselist {
    constructor(list) {
        super()
        this.fields = list
        this.fields.filter((val) => {
            val.date = new Date(val.date);
            val.datareal = val.date
            val.date = moment(val.date).format("L");
        });
        this.orderMatriculas(this.fields)
        this.createFields(this.fields)
    }
}

export class Contracts extends Baselist {
    constructor(list) {
        super()
        this.fields = list
        this.fields.filter((val) => {
            val.date = new Date(val.date);
            val.datareal = val.date
            val.date = moment(val.date).format("L");
        });
        this.orderContracts(this.fields)
        this.createFields(this.fields)
    }
}

export class MatriculasProccess extends Baselist {
    constructor(list) {

        super()
        this.fields = list
        this.fields.filter((val) => {
            val.data = new Date(val.data);
            val.datareal = val.data
            val.data = moment(val.data).format("L");
        });
        this.orderMatriculas(this.fields)
        this.createFields(this.fields)
    }

}

export class ContractsProccess extends Baselist {
    constructor(list) {

        super()
        this.fields = list
        this.fields.filter((val) => {
            val.date = new Date(val.date);
            val.datareal = val.date
            val.date = moment(val.date).format("L");
        });
        this.orderContracts(this.fields)
        this.createFields(this.fields)
    }

}